$(document).ready(function () {
  console.log("ready!");
  // if ($("#video").length > 0) {
  //   $("#video").YTPlayer({
  //     fitToBackground: false,
  //     //   width: "100%",
  //     videoId: "I4jcD3zue2w",
  //     playerVars: {
  //       modestbranding: 0,
  //       autoplay: 1,
  //       controls: 1,
  //       showinfo: 0,
  //       wmode: "transparent",
  //       branding: 0,
  //       rel: 0,
  //       autohide: 0,
  //       origin: window.location.origin
  //     }
  //   });
  // }
});